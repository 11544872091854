

.add-section-button {
    width: 100%;
    height: 100px;
    border-radius: 1rem;
    border: 1px dashed grey;
}

.text-1-line {
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
           line-clamp: 1; 
   -webkit-box-orient: vertical;
}